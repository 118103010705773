footer {
  background-color: #fdfdfd;
  padding: 30px 30px 10px 0;
  border-top: 1px solid lightgray;

  .row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    h4 {
      font-size: 15px;
      font-weight: 500;
    }
  }

  .space-1 {
    border-top: 1px solid #efefef;
    padding-top: 20px;
  }
}
