.preview-images {
  height: 360px;
  position: relative;
  border-radius: 6px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  overflow: hidden;
}

.preview-images-main {
  overflow: hidden;
  height: 100%;
  width: auto;
  flex: 1;
}


.images-view {
  margin-top: 16px;
  width: 100%;
}

.preview-images-second {
  width: 400px;
  margin-left: 4px;
  /* height: 50%; */
  height: 100%;
}

.preview-images-second-top {
  height: calc(50% - 2px);
  margin-bottom: 4px;
  overflow: hidden;
}

.preview-images-second-bottom {
  height: calc(50% - 2px);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.preview-images-second-bottom-left {
  width: calc(50% - 2px);
  height: 100%;
  overflow: hidden;
}

.preview-images-second-bottom-right {
  width: calc(50% - 2px);
  height: 100%;
  overflow: hidden;
  position: relative;
}

.preview-images-second-top > img {
  width: 100%;
  height: auto;
}

.preview-images-second-bottom-left > img {
  height: 100%;
  width: auto;
}

.preview-images-second-bottom-right > img {
  height: 100%;
  width: auto;
}

.preview-images-second-bottom-right > p {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00000075;
  top: 0;
  left: 0;
  color: white;
  font-size: 15px;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-images-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000066;
  z-index: 10;
  padding: 0 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mySwiper .swiper-slide {
  height: 50px;
  overflow: hidden;
  width: 80px;
}


.mySwiper .swiper-slide {
  height: 75px;
  width: 120px !important;
}

.mySwiper2 .swiper-slide img {
  width: auto;
  height: 500px;
}

.mySwiper2 {
  max-height: 500px;
}

.mySwiper {
  height: 75px;
  margin-top: 20px;
}

.mySwiper2 .swiper-slide {
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100% !important;
  padding: 0 0px;
  max-height: 100%;
  overflow: hidden;
}

.preview-images-popup-container {
  width: 100%;
  max-width: 1100px;
  background-color: black;
  height: auto;
  padding: 30px;
}

.mySwiper .swiper-slide img {
  width: 100%;
  height: auto;
}

.close-area {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 11;
  color: white;
  font-size: 20px;
}

.preview-images-main > img {
  width: 100%;
  height: auto;
}