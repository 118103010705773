.app-order {
  /* background-color: black; */
  margin: 25px auto;
}

.steps-order {
  max-width: 800px;
  margin: 0 auto;
}

img.login-image {
  width: 56px;
  height: 56px;
}

.login-container {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  margin: -4px;
  width: calc(100% + 8px);
}

span.login-info-text-title {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: rgb(83, 146, 249);
}

p.login-info-desc {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  margin: 0;
}

.login-info-text {
  -webkit-box-pack: justify;
  justify-content: space-between;
  display: flex;
}

.login-info {
  box-sizing: border-box;
  margin: 0px;
  flex-basis: 0px;
  -webkit-box-flex: 1;
  flex-grow: 1;
  max-width: 100%;
  padding: 4px;
}

.order-info {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  margin: 24px 0;
  margin-top: 40px;
}

.order-info-left {
  flex: 1;
  margin-right: 12px;
}

.order-info-right {
  width: 350px;
}

.form-row > label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.form-rows {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.form-rows > div {
  width: 49%;
}

.ant-checkbox + span {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.form-actions {
  margin-top: 20px;
}

.form-actions > p {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.form-actions > div {
  margin-top: 20px;
}

.hotel-od-info-left {
  flex-basis: 25%;
  -webkit-box-flex: 0;
  flex-grow: 0;
  max-width: 25%;
  overflow: hidden;
}

.hotel-od-info {
  box-sizing: border-box;
  display: flex;
  margin: -6px;
  width: calc(100% + 12px);
}

.hotel-od-info-left > img {
  max-height: 130px;
  width: auto;
}

.hotel-od-info-right {
  flex: 1;
  padding-left: 12px;
  padding-right: 12px;
}

.hotel-od-info-right > h3 {
  font-size: 17px;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 0px;
}

.hotel-od-info-right > p {
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;
  margin-bottom: 0;
}

.order-room {
  margin-top: 8px;
  margin-bottom: 8px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  display: flex;
}

.order-room-count-icon {
  margin-right: 8px;
  margin-left: 0px;
  width: 48px;
  height: 48px;
  position: relative;
  order: 0;
  display: flex;
}

span.order-room-count-value {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}

.order-room-count-icon > svg {
  fill: rgb(83, 146, 249);
  font-size: 48px;
  width: 48px;
  height: 48px;
  /* transform: scale(2) scaleX(-1); */
  position: absolute;
}

.order-room-count-icon > div {
  margin-right: 4px;
  margin-left: 0px;
  width: inherit;
  height: inherit;
  position: relative;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  display: inherit;
  color: rgb(255, 255, 255);
}

.order-room-count-info {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  display: flex;
  text-align: left;
  flex: 1;
}

.order-room-count-info-co {
  display: flex;
}

p.order-room-count-view {
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: rgb(83, 146, 249);
  margin: 0;
}

#root {
  background-color: #f7f7f7;
}

p.order-room-count-view-2 {
  font-size: 10px;
  line-height: 16px;
  font-weight: 400;
  margin: 0;
}

.row-if-order-room-user-icon {
  padding: 4px;
  font-size: 12px;
  fill: rgb(42, 42, 46);
}

.row-if-order-room-user {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  margin-top: 6px;
}

.row-if-order-room-user-icon > img {
  width: 21px;
  height: 21px;
}

.row-if-order-room-user-text {
  line-height: 30px;
  height: 30px;
  margin-left: 8px;
}

.price-section {
  background-color: #f9f9f9;
  padding: 16px;
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -16px;
  padding-bottom: 1px;
}

.price-section-row {
  margin-top: 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  justify-content: space-between;
  text-align: initial;
  margin: 0 -5px;
  margin-bottom: 15px;
  font-size: 14px;
}

span.price-section-free {
  color: #488bf8;
  text-transform: uppercase;
}

.TotalPrice__totalPrice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0;
}

.TotalPrice__totalPrice > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

span.TotalPrice__totalPriceLabel {
  font-size: 17px;
  font-weight: 400;
}

.TotalPrice__boldText {
  font-size: 16px;
  font-weight: 300;
}

div {
}

span.ChargeSummary__chargeText {
  font-size: 12px;
  font-weight: 300;
  color: #676767;
}

.SpeechBubble__speechBubble {
  background-color: #32A923;
  font-size: 12px;
  color: #fff;
  padding: 8px 8px 5px;
  margin: 14px 0 10px;
  position: relative;
  line-height: 1.33;
}

span.SpeechBubble__arrowUp.SpeechBubble {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 8px solid #32A923;
  position: absolute;
  top: -7px;
  right: 16px;
}
