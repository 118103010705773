.MasterRoom {
  border: 1px solid;
  margin-bottom: 30px;
}

.MasterRoom-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 12px 8px;
  border-bottom: 1px solid;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.MasterRoom-headerLeft {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 5px;
  overflow: visible;
  line-height: 30px;
}

.MasterRoom-headerTitle {
  font-size: 16px;
  font-weight: 500;
}

.MasterRoom-table {
  display: table;
  width: 100%;
  height: 100%;
}

.MasterRoom-row {
  display: table-row;
  height: 100%;
}

.MasterRoom-info {
  display: table-cell;
  vertical-align: top;
  width: 18%;
  box-sizing: border-box;
  position: relative;
  padding-left: 10px;
}

.MasterRoom-infoHeader {
  font-size: 14px;
  font-weight: 700;
  margin: 10px 0;
}

.ChildRoomsList--flex .ChildRoomsList-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 10px 0
}

.MasterRoom-infoPhotoContainer {
  position: relative;
}

.MasterRoom-roomImagePreview {
  background: transparent;
  position: absolute;
  top: 0;
  overflow: hidden;
  width: 400px;
  height: 225px;
  z-index: 1;
  transition: opacity .15s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.MasterRoom-infoPhoto {
  position: relative;
  overflow: hidden;
  height: 126px;
  cursor: pointer;
}

.MasterRoom-infoPhoto img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.MasterRoom-infoSeePhotos {
  display: block;
  margin: 3px 0 6px;
}

.MasterRoom-amenities {
  margin: 12px 0 4px;
  padding: 0;
  list-style-type: none;
}

.MasterRoom__animatedAmenity {
  transition: height .6s, opacity .6s;
  transition-delay: .6s;
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.MasterRoom__animatedAmenity--toggle {
  height: 30px;
  opacity: 1;
}

.MasterRoom-amenitiesItem {
  vertical-align: top;
  margin: 12px 0 0;
  font-size: 13px;
  line-height: 17px;
}

.MasterRoom-amenitiesItem > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.MasterRoom-amenitiesItem .ficon {
  margin-right: 12px;
  width: 16%;
  min-height: 0;
  vertical-align: middle;
  font-size: 20px;
}

.MasterRoom-amenitiesTitle {
  width: 80%;
  display: inline-block;
  vertical-align: middle;
}

.MasterRoom-roomsList {
  padding: 0 10px;
  display: table-cell;
  box-sizing: border-box;
  width: 72%;
  height: 100%;
  vertical-align: top;
}

.ChildRoomsList {
  display: table;
  width: 100%;
  height: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
  position: relative;
}

.ChildRoomsList--flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ChildRoomsList--flex .ChildRoomsList-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 10px 0;
}

.ChildRoomsList-headerCell {
  display: table-cell;
  font-size: 14px;
  font-weight: 700;
}

.ChildRoomsList-headerCellIncluded {
  width: 38%;
}

.ChildRoomsList-headerCellCapacity {
  width: 10%;
}

.ChildRoomsList-headerCellPrice {
  width: 22%;
}

.ChildRoomsList-headerCellRooms {
  width: 10%;
}

.ChildRoomsList-headerCellBook {
  width: 20%;
}

.ChildRoomsList--flex .ChildRoomsList-headerCell {
  display: inline-block;
  word-wrap: break-word;
  word-break: break-word;
}

.theme-agoda .ChildRoomsList-headerCell {
  color: #333;
}

.ChildRoomsList-room {
  display: table-row;
  width: 100%;
}

.ChildRoomsList-room:last-child {
  height: 100%;
}

.ChildRoomsList--flex .ChildRoomsList-room {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 10px;
  height: auto;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.ChildRoomsList--flex .ChildRoomsList-room-contents {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}

.ChildRoomsList--flex .ChildRoomsList-roomCell-featureBuckets {
  width: 38%;
}

.ChildRoomsList--flex .ChildRoomsList-roomCell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ChildRoomsList-roomCell-featureBuckets {
  padding: 0 12px 8px;
}

.ChildRoomsList-roomCell {
  display: table-cell;
  padding: 8px 12px;
  vertical-align: top;
}

.ChildRoomsList--flex .ChildRoomsList-room-featurebuckets {
  width: 100%;
}

.ChildRoomsList-room-featurebucket {
  margin-top: 12px;
}

.ChildRoomsList-room-bucketspan {
  font-size: 12px;
  font-weight: bold;
}

.theme-agoda .ChildRoomsList-roomFeature--green {
  color: #85c150;
}

.ChildRoomsList-roomFeature {
  display: inline-block;
  margin: 4px 0 0;
  line-height: 22px;
  width: 100%;
}

.RoomFeature__Title--plain {
  color: #2a2a2e;
}

.RoomFeature__Title {
  display: inline-block;
  width: 85%;
  font-size: 13px;
}

.theme-agoda .RoomFeature__Icon {
  color: #32a923;
}

.RoomFeature__Icon {
  margin-right: 8px;
}

.ChildRoomsList-roomCell.relativeCell .PriceContainer-Top {
  text-align: right;
}

.ChildRoomsList-roomCell.relativeCell .PriceContainer-Top {
  margin-bottom: 40px;
}

.PriceDisplay {
  text-align: right;
  min-height: 0;
}

.PriceDisplay__TaxMessage {
  color: grey;
}

.PriceDisplay__TaxMessage {
  font-size: 10px;
  margin: 2px 0 8px;
}

.PriceDisplay div .pd-price {
  color: #ed5c59;
}

.PriceDisplay .swap .pd-price {
  margin-right: 4px;
}

.PriceDisplay div .pd-price {
  font-size: 24px;
}

.ChildRoomsList--flex .ChildRoomsList-roomCell-capacity {
  width: 10%;
}

.ChildRoomsList--flex .ChildRoomsList-roomCell-price {
  width: 22%;
}

.ChildRoomsList--flex .ChildRoomsList-roomCell-selector {
  width: 10%;
}

.ChildRoomsList--flex .ChildRoomsList-roomCell-book {
  width: 20%;
  padding: 0;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.ChildRoomsList-packageRoomCountPanel {
  border-color: #dddfe2;
  background-color: #e9ebee;
}

.ChildRoomsList-packageRoomCountPanel {
  padding: 10px 0;
  text-align: center;
  border: 1px solid;
  font-size: 16px;
  cursor: not-allowed;
}

.ChildRoomsList-roomDropdownWrapper {
  position: relative;
  width: 100%;
}

.ChildRoomsList--flex .ChildRoomsList-room-book {
  width: 100%;
  padding: 8px 12px;
}

.ChildRoomsList-bookButtonInput {
  color: #fff;
  background-color: #5392f9;
}

.ChildRoomsList-bookButtonInput {
  width: 100%;
  border: none;
  border-radius: 3px;
  height: auto;
  font-size: 13px;
  font-weight: 700;
  line-height: 22px;
  padding: 10px 0;
}
