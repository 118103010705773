.Searchbox__wrapper.detail-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1140px !important;
}

.container-trippyasia {
  box-sizing: border-box;
  overflow: hidden;
  margin: 0 auto;
  width: 1140px;
  display: flex;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  height: 44px;
}

.BreadCrumb-hotel {
  list-style-type: none;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
  font-size: 12px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.BreadCrumb-hotel_item .breadcrumbRegionName__h1 {
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;
}

.BreadCrumb-hotel_item {
  margin-right: 10px;
}

.property-app .property-breadcrumb-container {
  border-bottom: 0;
  background-color: transparent;
  height: 44px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.BadgeHolder {
  margin-right: 8px;
  margin-left: 0px;
  padding: 4px;
  height: 29px;
  display: inline-flex;
  border-radius: 4px;
  border: 1px solid rgb(50, 169, 35);
}

.BadgeHolder svg {
  width: 18px;
  height: 18px;
}

.BadgeHolderText {
  color: rgb(40, 135, 28);
  font-size: 12px;
  margin-left: 6px;
  margin-right: 10px;
}

.product-name {
  display: flex;
  /* font-size: 17px; */
  margin-bottom: 9px;
  margin-top: 15px;
}

.HeaderCerebrum__Name {
  font-weight: bold;
  margin-right: 10px;
  margin-bottom: 0;
}

.price-view {
  position: absolute;
  left: 12px;
  top: 12px;
  background: #ee595d;
  height: 45px;
  color: white;
  padding: 0 15px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.price-view > p {
  margin: 0;
  font-size: 10px;
  margin-bottom: 3px;
}

.price-view > h5 {
  margin: 0;
  font-weight: bold;
  color: white;
  font-size: 16px;
}

.feature-item-view {
  width: 25%;
  border-right: 1px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
}

.feature-item-view > img {
  width: 30px;
}

.feature-item-view span {
  font-size: 11px;
  line-height: 15px;
  font-weight: 300;
  color: rgb(115, 115, 115);
  display: block;
}

.feature-item-view p {
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 3px;
  font-weight: bold;
}

.feature-item-view > div {
  margin-left: 10px;
}

.feature-item-view:last-child {
  border: none;
  padding-right: 0;
}

.feature-item-view:first-child {
  padding-left: 0;
}

.booking-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(221, 223, 226);
  padding-bottom: 12px;
  margin-bottom: 12px;
  margin-top: 24px;
}

h3.kite-js-Typography {
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  margin: 0px;
}
