.theme-trippyasia .WelcomeMessage * {
    color: white;
}

.tab-selection-active {
    color: #5392f9;
}

.tab-selection-active h6 {
    color: #5392f9 !important;
}

.tab-selection-active:after {
    content: '';
    width: 100%;
    position: absolute;
    height: 3px;
    left: 0;
    bottom: 0;
    background-color: #5392f9;
}
